import React from 'react';
import classNames from 'classnames';

import formatCurrency from 'utils/currency/numberToPrice';
import formatPercent from 'utils/percent/formatPercent';
import { formatLocaleDateShort } from 'utils/date/formatDate';

import formatNumber from 'utils/number/format';
import { getOptionsValueLabel } from 'utils/normalizers/dropdown';
import { MlsListingStatus, LienTypeCode, EstimatedValueGrowthPeriod, ForeclosureStatusOptions, LoanTypes, FinancingTypes } from 'data/search';
import { PropertyClassOptions, PropertyTypeOptions, TaxExemptionOptions } from 'data/property';
import css from './style.scss';


const growthPeriodOptions = getOptionsValueLabel(EstimatedValueGrowthPeriod).slice(1);

const OccupancyOptions = [
  { label: 'Occupied', value: 'false' },
  { label: 'Vacant', value: 'true' },
  { label: 'Any', value: '' },
];

const IncludeExcludeOptions = [
  { label: 'Include', value: 'true' },
  { label: 'Exclude', value: 'false' },
  { label: 'Any', value: '' },
];

const MlsListingTypeOptions = [
  { label: 'For Sale', value: 'false' },
  { label: 'For Rent', value: 'true' },
  { label: 'Any', value: '' },
];

const DeceasedOptions = [
  { label: 'Owner Deceased', value: 'OWNER' },
  { label: 'Related Deceased', value: 'RELATED' },
];

const OwnershipOptions = [
  { label: 'Individual', value: 'INDIVIDUAL' },
  { label: 'Corporate', value: 'CORPORATE' },
  { label: 'Mixed', value: 'MIXED' },
  { label: 'Trust', value: 'TRUST' },
];

export const ListingTypeOptions = [
  { label: 'SBTW BRRR SFR Owner Occ', value: 'SBTW1' },
  { label: 'SBTW BRRR SFR Non Owner', value: 'SBTW2' },
  { label: 'SBTW BRRR Multi Fam', value: 'SBTW3' },
  { label: 'SBTW Vacant Land', value: 'SBTW4' },
  { label: 'On Market', value: 'M' },
  { label: 'Vacant', value: 'V' },
  { label: 'Liens', value: 'L' },
  { label: 'Pre-Foreclosures', value: 'P' },
  { label: 'Auctions', value: 'A' },
  { label: 'Bank Owned', value: 'F' },
  { label: 'Cash Buyers', value: 'C' },
  { label: 'High Equity', value: 'E' },
  { label: 'Free & Clear', value: 'R' },
  { label: 'Bankruptcy', value: 'B' },
  { label: 'Divorce', value: 'D' },
  { label: 'Tax Delinquencies', value: 'X' },
  { label: 'Flippers', value: 'I' },
  { label: 'Failed Listings', value: 'Z' },
  { label: 'Senior Owners', value: 'S' },
  { label: 'Vacant Land', value: 'VLN' },
  { label: 'Upside Down', value: 'UD' },
  { label: 'Tired Landlords', value: 'T' },
  { label: 'Zombie Properties', value: 'ZOM' },
  { label: 'Pre-Probate (Deceased Owners)', value: 'DEC' },
];

export const leftPanelCategories = [
  {
    key: 'll',
    name: "Lead Lists",
    description:
      "Preset filters to quickly search & strategize your next move"
  },
  {
    key: 'pb',
    name: "Property Details",
    description:
      "Filter by the number of beds, baths, property types, building size, and other characteristics"
  },
  {
    key: 'mls',
    name: "MLS",
    description:
      "Keyword search MLS Details, Listing Date or Amount, and On or Off Market"
  },
  {
    key: 'pfbo',
    name: "Pre-Foreclosure & Banked Owned",
    description: "Filter Foreclosure Status, Dates, and Amounts"
  },
  {
    key: 'oio',
    name: "Owner Information & Occupancy",
    description:
      "Select to view specific information about owner and property occupancy"
  },
  {
    key: 'lbd',
    name: "Lien, Bankruptcy, & Divorce",
    description:
      "Include or Exclude Liens, Bankruptcy, or Divorce Status Information"
  },
  {
    key: 've',
    name: "Value & Equity",
    description: "Filter by the number of beds,baths,property type, building size and other characteristics"
  },
  {
    key: 'm',
    name: "Mortgage",
    description: "Search via number of open mortgages remaining balance,and more"
  }
];


const OwnerLocationOptions = [
  { label: 'Out of State', value: 'OUT_OF_STATE' },
  { label: 'Out of County', value: 'OUT_OF_COUNTY' },
  { label: 'Local', value: 'LOCAL' },
];

const LienStatusOptions = [
  { label: 'Has Active Lien', value: 'LIEN' },
  { label: 'Has Active Bankruptcy', value: 'BANKRUPTCY' },
  { label: 'Has Divorce', value: 'DIVORCE' },
  { label: 'Lien Released', value: 'LIEN_RELEASED' },
  { label: 'Bankruptcy Dismissed', value: 'BANKRUPTCY_DISMISSED' },
];

const OpenMortgageOptions = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
];

const PropertyFeatureOptions = [
  { label: 'Pool', value: 'POOL' },
  { label: 'Garage', value: 'GARAGE' },
  { label: 'Basement', value: 'BASEMENT' },
  { label: 'Attic', value: 'ATTIC' },
];

const FilterSections = [
  {
    name: 'MAIN',
    fields: [
      { name: 'ownerOccupied', type: 'boolean', label: 'Owner Occupied' },
      { name: 'listingType', type: 'text', label: 'Quick List Choices', options: ListingTypeOptions, tooltip: 'Trigger choices are quick ways to target specific motivated seller categories.' },
      { name: 'vacant', type: 'boolean', label: 'Occupancy Status', options: OccupancyOptions },
    ],
  },
  {
    name: 'PROPERTY',
    title: 'Property Characteristics',
    fields: [
      { name: 'propertyClassCode', type: 'list', label: 'Property Classification(s)', options: PropertyClassOptions, tooltip: 'Search by specific property classifications.' },
      { name: 'landUseCode', type: 'list', label: 'Property Type(s)', options: PropertyTypeOptions },
      { name: 'bedrooms', type: 'number', label: 'Bedrooms' },
      { name: 'bathrooms', type: 'decimal', label: 'Bathrooms' },
      { name: 'squareFeet', type: 'number', label: 'Building Size (SqFt)' },
      { name: 'lotSquareFeet', type: 'number', label: 'Lot Size (SqFt)' },
      { name: 'yearBuilt', type: 'number', label: 'Year Built' },
      { name: 'propertyFeatures', type: 'list', label: 'Home Features', options: PropertyFeatureOptions },
      { name: 'units', type: 'number', label: 'Unit Number Range' },
      { name: 'stories', type: 'number', label: 'Number of Stories' },
      { name: 'schoolDistrict', type: 'text', label: 'School District' },
      { name: 'hoaPresent', type: 'boolean', label: 'Association (HOA/COA)', options: IncludeExcludeOptions },
      { name: 'poolType', type: 'text', label: 'Pool Type' },
      { name: 'garageType', type: 'text', label: 'Garage Type' },
      { name: 'basementType', type: 'text', label: 'Basement Type' },
    ],
  },
  {
    name: 'MLS',
    title: 'MLS Status',
    fields: [
      { name: 'forSale', type: 'boolean', label: 'On Market' },
      { name: 'rental', type: 'boolean', label: 'Listing Type', options: MlsListingTypeOptions },
      { name: 'mlsListingStatus', type: 'list', label: 'MLS Status', options: MlsListingStatus },
      { name: 'mlsListingDate', type: 'date', label: 'MLS Listing Date' },
      { name: 'daysOnMarket', type: 'number', label: 'Days On Market' },
      { name: 'mlsListingAmount', type: 'currency', label: 'MLS Listing Amount' },
      { name: 'belowMarketPrice', type: 'boolean', label: 'Listed Below Market Price' },
      { name: 'mlsListingKeyword', type: 'text', label: 'MLS Keyword(s)' },
      // { name: 'mlsFailDate', type: 'date', label: 'Failed Listing Date' },
      // { name: 'rental', type: 'boolean', label: 'MLS Rental' },
    ],
  },
  {
    name: 'FORECLOSURE',
    title: 'Pre-Foreclosure & Bank Owned',
    fields: [
      { name: 'foreclosureStatus2', type: 'list', label: 'Foreclosure Status', options: ForeclosureStatusOptions },
      { name: 'foreclosureRecordingDate', type: 'date', label: 'Foreclosure Recording Date' },
      { name: 'auctionDate', type: 'date', label: 'Auction Date', futureDateEnabled: true },
      { name: 'openingBidAmount', type: 'currency', label: 'Opening Bid' },
      { name: 'foreclosureReleaseDate', type: 'date', label: 'Pre-Foreclosure Release Date' },
      { name: 'defaultAmount', type: 'currency', label: 'Default Amount' },
      // { name: 'documentTypeCode', type: 'list', label: 'Document Type', options: DocumentTypeCode },
    ],
  },
  {
    name: 'OWNERSHIP',
    title: 'Ownership Info',
    fields: [
      { name: 'ownershipLength', type: 'number', label: 'Years of Ownership' },
      { name: 'ownerType', type: 'list', label: 'Owner Type', options: OwnershipOptions },
      { name: 'saleDate', type: 'date', label: 'Last Sale Date' },
      { name: 'saleAmount', type: 'currency', label: 'Last Sale Price' },
      { name: 'propertiesOwned', type: 'number', label: 'Number of Properties Owned' },
      { name: 'exemptions', type: 'list', label: 'Tax Exemption Status', options: TaxExemptionOptions },
      { name: 'ownerLocation', type: 'list', label: 'Absentee Owner Location', options: OwnerLocationOptions },
      // { name: 'ownerDeceasedCode', type: 'list', label: 'Owner Deceased', options: DeceasedOptions },
      { name: 'ownerDeceased', type: 'boolean', label: 'Pre-Probate (Deceased Owners)', options: IncludeExcludeOptions },
      { name: 'interFamilyTransfer', type: 'boolean', label: 'Intra-Family Transfer', options: IncludeExcludeOptions },
      { name: 'missingSaleIncluded', type: 'boolean', label: 'Include Unknown Sale Dates' },
    ],
  },
  {
    name: 'LIEN',
    title: 'Lien/Bankruptcy/Divorce Status',
    fields: [
      { name: 'lienStatus', type: 'list', label: 'Quick Target Status', options: LienStatusOptions },
      { name: 'lienTypeCode', type: 'list', label: 'Specific Lien Type', options: LienTypeCode },
      { name: 'lienStatusExclude', type: 'list', label: 'Quick Suppress Status', options: LienStatusOptions },
      { name: 'lienRecordingDate', type: 'date', label: 'Lien Recording Date' },
      { name: 'bankruptcyRecordingDate', type: 'date', label: 'Bankruptcy Recording Date' },
      { name: 'bankruptcyChapter', type: 'list', label: 'Bankruptcy Chapter' },
      { name: 'lienAmount', type: 'currency', label: 'Lien Amount' },
      { name: 'divorceRecordingDate', type: 'date', label: 'Divorce Filing Date' },
      { name: 'delinquentTaxYear', type: 'number', label: 'Tax Delinquent Year' },
    ],
  },
  {
    name: 'VALUE',
    title: 'Valuation & Equity Info',
    fields: [
      { name: 'estimatedValue', type: 'currency', label: 'Estimated Value' },
      { name: 'estimatedRentalIncome', type: 'currency', label: 'Estimated Rental Income' },
      { name: 'grossYield', type: 'percent', label: 'Gross Yield %' },
      { name: 'estimatedValueGrowth', type: 'percent', label: 'Growth %', negativeAllowed: true },
      { name: 'estimatedValueGrowthPeriod', type: 'text', options: growthPeriodOptions },
      { name: 'estimatedEquity', type: 'currency', label: 'Estimated Equity $', negativeAllowed: true },
      { name: 'estimatedEquityPercent', type: 'percent', label: 'Estimated Equity %', negativeAllowed: true },
      { name: 'loanToValueRatio', type: 'percent', label: 'Loan to Value (LTV) %', negativeAllowed: true },
      { name: 'assessedValue', type: 'currency', label: 'Assessed Total Value' },
      { name: 'assessedImprovementValue', type: 'currency', label: 'Assessed Improvement Value' },
      { name: 'assessedLandValue', type: 'currency', label: 'Assessed Land Value' },
      { name: 'itvRatio', type: 'percent', label: 'Improvement to Tax Value %', negativeAllowed: true },
      { name: 'unknownEquityIncluded', type: 'boolean', label: 'Include Unknown Equity' },
      { name: 'unknownLtvIncluded', type: 'boolean', label: 'Include Unknown LTV %' },
    ],
  },
  {
    name: 'MORTGAGE',
    title: 'Mortgage Info',
    fields: [
      { name: 'openMortgageQuantity', type: 'list', label: '# of Open Mortgages', options: OpenMortgageOptions },
      { name: 'openMortgageBalance', type: 'currency', label: 'Open Mortgage Remaining Balance' },
      { name: 'mortgageLoanCode', type: 'list', label: 'Loan Type(s)', options: LoanTypes },
      { name: 'mortgageRecDate', type: 'date', label: 'Mortgage Recording Date' },
      { name: 'mortgageTotalPayment', type: 'currency', label: 'Total Monthly Payments' },
      { name: 'mortgageInterestRate', type: 'percent', label: 'Interest Rate % (Est.)' },
      { name: 'mortgageFinancingCode', type: 'list', label: 'Interest Rate Type(s)', options: FinancingTypes },
      // { name: 'mortgageRefinance', type: 'boolean', label: 'Refinance', options: IncludeExcludeOptions },
      // { name: 'mortgageRefinanceDate', type: 'date', label: 'Refinance Date Range' },
      // { name: 'mortgageLenderName', type: 'text', label: 'Lender Name(s)', description: 'Separated by "," - 4 character min. per name' },
      // { name: 'mortgageMaturityDate', type: 'date', label: 'Last Loan Due Date', futureDateEnabled: true },
      { name: 'mortgage1LtvRatio', type: 'percent', label: '1st Loan to Current Value (1LTV) %', negativeAllowed: true },
      { name: 'mortgage1EstimatedEquity', type: 'currency', label: '1st Loan to Current Value (1LTV) Equity $', negativeAllowed: true },
      { name: 'cashBuyer', type: 'boolean', label: 'Cash Buyer', options: IncludeExcludeOptions },
      { name: 'freeClear', type: 'boolean', label: 'Free & Clear', options: IncludeExcludeOptions },
      { name: 'mortgageSellerCarried', type: 'boolean', label: 'Seller Carry Back', options: IncludeExcludeOptions },
    ],
  },
  // ...[1, 2, 3, 4].reduce((sections, idx) => ([
  //   ...sections,
  //   {
  //     name: `MORTGAGE${idx}`,
  //     title: `Loan ${idx} Info`,
  //     fields: [
  //       { name: `mortgage${idx}RecordingDate`, type: 'date', label: `Loan ${idx} Origination Date` },
  //       { name: `mortgage${idx}Balance`, type: 'currency', label: `Loan ${idx} Balance` },
  //       { name: `mortgage${idx}LoanCode`, type: 'list', label: 'Loan Type(s)', options: LoanTypes },
  //       { name: `mortgage${idx}LenderName`, type: 'text', label: 'Lender Name(s)', description: 'Separated by "," - Delayed Results Expected' },
  //       { name: `mortgage${idx}InterestRate`, type: 'percent', label: `Loan ${idx} Interest Rate % (Est.)` },
  //       { name: `mortgage${idx}FinancingCode`, type: 'list', label: `Loan ${idx} Interest Rate Type(s)`, options: FinancingTypes },
  //       { name: `mortgage${idx}Payment`, type: 'currency', label: `Loan ${idx} Payment Amount` },
  //       { name: `mortgage${idx}DueDate`, type: 'date', label: `Loan ${idx} Due Date`, futureDateEnabled: true },
  //       { name: `mortgage${idx}Refinance`, type: 'boolean', label: 'Refinance', options: IncludeExcludeOptions },
  //       { name: `mortgage${idx}RefinanceDate`, type: 'date', label: `Refinance ${idx} Date Range` },
  //       { name: `mortgage${idx}Equity`, type: 'boolean', label: 'Equity Loan', options: IncludeExcludeOptions },
  //     ],
  //   },
  // ]), []),
];

// eslint-disable-next-line no-param-reassign
FilterSections.forEach(s => s.fields.forEach((f) => { f.section = s; f.ignore = !f.label; if (!('range' in f)) f.range = ['number', 'percent', 'currency', 'decimal', 'date'].includes(f.type); }));

export const FilterSectionMap = FilterSections.reduce((map, section) => ({ ...map, [section.name]: section }), {});
export const FilterFieldMap = FilterSections.reduce((ar, s) => ar.concat(s.fields), [
  { name: 'name', type: 'text', label: 'Summary Name' },
  { name: 'description', type: 'textarea', label: 'Summary Description' },
  { name: 'defaultFilter', type: 'boolean', label: 'Use as the default for all groups.' },
]).reduce((map, f) => ({ ...map, [f.name]: f }), {});

function getValue(search, { type, options }, name) {
  let value = search.get(name);
  const opts = options && options.filter(o => o.value !== '');
  const year = name.toLowerCase().includes('year');

  const valType = typeof value;
  if (value == null
    || valType === 'undefined'
    || (valType === 'string' && value.trim() === '')
    || (['number', 'currency', 'percent', 'decimal'].includes(type) && isNaN(value))
    || (opts && valType === 'object' && (!value.size || opts.length === value.filter(v => !!v).size))
  ) return null;

  if ((type === 'number' || type === 'decimal') && !year) return formatNumber(value);
  if (type === 'currency') return formatCurrency(value);
  if (type === 'percent') return formatPercent(Number(value) * 100);
  if (type === 'boolean' && !opts) return !value ? 'No' : 'Yes';
  if (type === 'date') return formatLocaleDateShort(value);
  if (opts) {
    if (value == null || value === undefined) value = '';
    const ar = typeof value === 'object' ? value : String(value).split(',');

    return opts.filter(o => value !== null && ar.includes(String(o.value))).map(o => o.label).join(' / ');
  }

  return String(value);
}

export const getFieldName = name => name.substring(0, name.length - (/(Min)|(Max)$/.test(name) ? 3 : 0));

const getFields = (search) => {
  const sectionCounts = {};
  const fields = [];
  Object.keys(FilterFieldMap).forEach((name) => {
    const field = { ...FilterFieldMap[name] };
    if (field.section) {
      const { range, ignore, section: { name: sectionName } } = field;

      if (!range) field.value = getValue(search, field, name);
      else {
        field.valueMin = getValue(search, field, `${name}Min`);
        field.valueMax = getValue(search, field, `${name}Max`);
      }

      if (field.value || field.valueMin || field.valueMax) {
        fields.push(field);
        if (!ignore) sectionCounts[sectionName] = (sectionCounts[sectionCounts] || 0) + 1;
      }
    }
  });

  return { fields, sectionCounts };
};

const writeField = (field, onClick, max = false) => {
  const { ignore, range, value = null, valueMin = null, valueMax = null, type, name } = field;
  if (ignore || (max && (valueMax == null)) || (!max && ((value || valueMin) == null))) return null;

  const fullRange = valueMin != null && valueMax != null;
  const year = name.toLowerCase().includes('year');

  const classes = classNames(css.fieldValue, {
    [css.rangeValue]: range && (!max || !fullRange),
    [css.fullRange]: fullRange,
    [css.minValue]: !max,
    [css.maxValue]: max,
    [css.numberValue]: !year && ['number', 'percent', 'decimal', 'currency'].includes(type),
    [css.quantityValue]: name === 'bathrooms' || (type === 'number' && !name.endsWith('Feet')),
    [css.dateValue]: year || type === 'date',
  });

  return <div className={classes} onClick={() => onClick(field, max)}>{max ? valueMax : valueMin || value}</div>;
};

export const getDisplayFields = fields => fields.filter(f => !f.ignore);

export const writeFields = (fields, onFieldClick, onFieldClear) => {

  const valueDescClass = classNames(css.fieldValue, css.valueDesc);
  const growthPeriod = fields.find(f => f.name === 'estimatedValueGrowthPeriod');
  const onClick = onFieldClick || (() => { });
  const displayFields = getDisplayFields(fields);

  return (
    <div className={classNames(css.fields, { [css.editable]: !!onFieldClick })}>
      {displayFields.length ? null : <div className={css.noCriteria}>No Additional Criteria Entered</div>}
      {displayFields.map(field => (
        <div className={css.field} key={field.name}>
          <div className={css.fieldLabel} onClick={() => onClick(field)}>{field.label}:</div>
          <div className={css.fieldValues}>
            {writeField(field, onClick)}
            {writeField(field, onClick, true)}
            {field.name !== 'estimatedValueGrowth' ? null : <div className={valueDescClass} onClick={() => onClick(growthPeriod)}>over {growthPeriod.value.toLowerCase()}</div>}
            {field.name !== 'mortgageTerm' ? null : <div className={valueDescClass} onClick={() => onClick(field)}>{`month${field.valueMin != null && Number(field.valueMax) === 1 ? '' : 's'}`}</div>}
          </div>
          {!onFieldClear ? null : <img src={"/assets/images/clear_btn_red.png"} className={css.fieldClear} onClick={() => onFieldClear(field)} />}
        </div>
      ))}
    </div>
  );
};

export const getField = name => FilterFieldMap[getFieldName(name)];

export default getFields;
