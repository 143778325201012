/** @flow */
/** @module utils/date/formatDate */
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import config from 'config';


momentTimezone.tz.setDefault('Etc/UTC');

const { constants: { FORMAT } } = config;
const holidays = [6, 0];

export function getNow(utc) {
  if (utc) return moment.utc();
  return moment();
}

export function getTomorrow(now) {
  return moment(now).add(1, 'd');
}

export function getNextBusinessDay(now) {
  const tomorrow = getTomorrow(now);
  if (!holidays.includes(tomorrow.day())) return tomorrow;
  return getNextBusinessDay(tomorrow);
}

export function getDaysShift(now, quantity, excludeHolidays) {
  const shifted = moment(now).add(quantity, 'd');

  if (!excludeHolidays) return shifted;
  if (!holidays.includes(shifted.day())) return shifted;

  return getNextBusinessDay(shifted);
}

export function getNextWeek(now) {
  return moment(now).add(7, 'd');
}

export default function formatDate(
  somth,
  invalidDate = null,
  unix = false,
  // format = FORMAT.DATE,
  format = 'MM/DD/YYYY',
) {
  if (!somth) return invalidDate;
  const date = (unix ? moment.unix(somth) : moment(somth)).utc(false);
  if (!date.isValid()) return invalidDate;

  return date.format(format);
}

export function formatLocaleDate(
  somth,
  invalidDate = null,
  unix = false,
  // format = FORMAT.DATE,
  format = 'MM/DD/YYYY',
) {
  if (!somth) return invalidDate;
  const date = (unix ? moment.unix(somth) : moment.parseZone(somth)).utc(false);
  if (!date.isValid()) return invalidDate;

  return date.local().format(format);
}

export function formatUTCDate(
  somth,
  invalidDate = null,
  unix = false,
  // format = FORMAT.DATE,
  format = 'MM/DD/YYYY',
) {
  if (!somth) return invalidDate;
  const date = (unix ? moment.unix(somth) : moment(somth)).utc(false);
  if (!date.isValid()) return invalidDate;

  return date.format(format);
}

export const formatDateShort = (date) => formatDate(date, '', false, 'MM/DD/YY');

export const formatLocaleDateShort = (date) => formatLocaleDate(date, '', false, 'MM/DD/YY');

export const formatDateYearMonth = (date) => formatDate(date, '', false, 'MM/YYYY');

export const formatLongDate = (date) => formatDate(date, '', false, 'MMMM DD, YYYY');

export function formatTime(somth) {
  const date = moment.parseZone(somth);
  if (!date.isValid()) return '';

  return date.format('h:mm A')
}

export function formatLocalTime(somth) {
  const date = moment.parseZone(somth);
  if (!date.isValid()) return '';

  return date.local().format('h:mm A')
}

export function getUnixTimestamp(somth, invalidDate = 0) {
  const date = moment(somth);
  if (!date.isValid()) return invalidDate;

  return date.unix();
}

export function getISOString(
  somth,
  gmtOffset = 0,
  hours = 0,
  minutes = 0,
  invalidDate = 'Invalid date',
) {
  const date = moment.parseZone(somth);
  if (!date.isValid()) return invalidDate;

  date.hours(hours).minutes(minutes).seconds(0);
  const minutesOffset = date.utcOffset() - gmtOffset;
  date.utcOffset(gmtOffset).add(minutesOffset, 'm');

  return date.format();
}

export function getGMTOffset(somth) {
  const date = moment.parseZone(somth);
  if (!date.isValid()) return 0;

  return date.utcOffset();
}

export function getGMTRange() {
  const date = moment();
  const gmtRange = [];
  for (let gmtValue = -720; gmtValue <= 840; gmtValue += 60) {
    date.utcOffset(gmtValue);
    gmtRange.push({
      label: `(GMT ${date.format('Z')})`,
      value: gmtValue,
    });
  }

  return gmtRange;
}

export function getTimeRange() {
  const date = moment();
  const timeRange = [];
  let hoursValue = 0;
  let minutesValue = 0;
  do {
    date.hours(hoursValue).minutes(minutesValue);
    timeRange.push({
      label: date.format('h:mm A'),
      value: date.format('HH:mm'),
    });
    minutesValue += 30;
    if (minutesValue >= 60) {
      minutesValue = 0;
      ++hoursValue;
    }
  } while (hoursValue < 24);

  return timeRange;
}

export function getTimeString(somth) {
  const date = moment.parseZone(somth);
  if (!date.isValid()) return '';

  return date.format('HH:mm');
}

export function getDate(somth) {
  const date = moment.parseZone(somth);
  const currentDate = moment();
  if (!date.isValid()) return currentDate.format();

  const minutesOffset = date.utcOffset() - currentDate.utcOffset();
  date.utcOffset(currentDate.utcOffset()).add(minutesOffset, 'm');

  return date.format();
}

export function getTime(scheduledTime) {
  if (!scheduledTime) return '00:00';
  const time = (scheduledTime).split('T')[1].split('+')[0].split(':');
  return `${time[0]}:${time[1]}`;
}

export const today = () => moment().startOf('day');
