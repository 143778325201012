import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import Confirm from 'app/components/Confirm';
import UpgradeAccount from 'app/components/UpgradeAccount';
import Button from 'components/base/Button';
import Radio from 'components/base/Radio';
import Modal from 'components/base/Modal';
import Dropdown from 'components/base/Dropdown';
import { pluralize } from 'utils/string';
import { selectProfile } from 'data/user';
import numberFormat from 'utils/number/format';
import Checkbox from 'components/base/Checkbox';

import css from './style.scss';
import {formatDateShort} from "../../../utils/date/formatDate";


const BlankGroup = { id: -1, name: '' };

class AddToGroup extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleUpgradeClick = this.handleUpgradeClick.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    const { groups, size, itemType, save, name, groupType } = props;
    this.itemLabel = pluralize(itemType, size);
    this.groups = groups.filter(g => g.id && !g.standard && (!save || !g.defaultGroup)).map(g => ({ ...g, displayName: `${g.name}${!g.monitored || groupType === 'Automated List' ? '' : ' (Automated)'}` }));

    const selection = this.groups[0] || BlankGroup;

    this.state = {
      approvedQuantity: null,
      savedQuantity: null,
      savedGroup: (groups.find(g => g.defaultGroup) || {}).name,
      complete: false,
      selection,
      name: selection ? selection.name : (name || (save ? null : selection.name)),
      mode: groups.length ? 'add' : 'new',
      checkOptions: (props.checkOptions || []).map(({ id, label, checked }) => ({ id, label, checked: !!checked })),
    };
  }

  componentWillMount() {
    const { size, limit: approvedQuantity = size, groupType, confirm, closePopup, save = onComplete => onComplete(null) } = this.props;

    const doSave = () => save(savedQuantity => this.setState({ approvedQuantity, savedQuantity }));

    if (size <= approvedQuantity) doSave();
    else {
      const label = `${approvedQuantity} ${this.itemLabel.toLowerCase()}`;
      const question = `You can save up to ${label} at a time into your ${groupType}. Click Continue to save the first ${label} into your ${groupType}, or click Cancel to change your selection.`;

      confirm({ onCancel: closePopup, onOk: doSave, question });
    }
  }

  componentWillUnmount() {
    const { onComplete } = this.props;
    if (this.state.savedQuantity && onComplete) onComplete();
  }

  handleCheck(ev) {
    const { name, checked } = ev.target || ev;

    this.setState({ checkOptions: this.state.checkOptions.map(o => ({ ...o, checked: (String(o.id) === String(name) && checked) || o.checked })) });
  }

  handleSubmit(ev) {
    ev.preventDefault();

    const { confirm, add, closePopup } = this.props;
    const { mode, selection, checkOptions } = this.state;
    const name = this.state.name;

    if (name === '') confirm({ cancelLabel: null, question: 'Please enter a name.' })();
    else {
      add(name, (savedQuantity, message) => {
        if (!savedQuantity && message) {
          closePopup();
          confirm({ cancelLabel: null, question: message });
        } else this.setState({ complete: true, savedQuantity, savedGroup: name });
      }, { checkOptions, selection: mode === 'new' ? null : selection });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    const state = { [name]: value };
    if (name === 'mode') state.name = value === 'add' ? this.groups[0].name : '';

    this.setState(state);
  }

  handleSelectionChange(ev) {
    const { value } = ev.target;
    const selection = this.groups.find(g => String(g.id) === String(value));

    this.setState({ selection, name: selection.name });
  }

  handleAddClick() {
    this.setState({ name: this.groups[0] || '' });
  }

  handleUpgradeClick() {
    this.props.openUpgradeAccount();
  }

  render() {
    const { groupType, loading, closePopup, save, itemType, addPropertyMode, profile, newEnabled } = this.props;
    const { name, mode, savedQuantity, approvedQuantity, complete, savedGroup, selection, checkOptions } = this.state;
    const { fullAccount, overdueAccount, assignedSavedPropertyLimit, activeSavedPropertyLimit, remainingSavedProperties, catalogProducts, csPhone, subUser, nextCycleDate } = profile;
    const itemLabel = pluralize(itemType, savedQuantity || 0);
    const nameMode = name != null && !complete;
    const properties = numberFormat(assignedSavedPropertyLimit);

    const btn = { isLoading: loading, size: Button.size.large, kind: Button.kind.default };

    const modes = [{ value: 'add', label: `Add to ${groupType}` }];
    if (newEnabled) modes.push({ value: 'new', label: 'Create New' });

    return (
      <Modal
        isOpen={!!approvedQuantity}
        uniqId="addToGroup"
        caption={complete || savedQuantity === 0 ? `${savedQuantity ? '' : 'No '}${itemLabel} Saved` : `Add to ${groupType}`}
        width="420px"
      >
        <div>
          {!complete && savedQuantity == null ? null : (
            <div key="top" className={css.top}>
              <div className={css.caption}>{savedQuantity} {itemLabel.toLowerCase()} saved to <q>{savedGroup}</q>.</div>
              <div className={css.buttons}>
                {complete || !save || savedQuantity === 0 ? null : <Button onClick={this.handleAddClick} className={css.add} disabled={nameMode} size={Button.size.large} kind={Button.kind[nameMode ? 'blue' : 'default']}>Add to {groupType} (optional)</Button>}
                {nameMode ? null : <Button kind={Button.kind.redGhost} onClick={closePopup}>Close</Button>}
              </div>
            </div>
          )}
          {!addPropertyMode ? null : (
            <div>
              {fullAccount ? null : (
                <div className={css.trial}>
                  You are currently in your trial period, which gives you access to {numberFormat(activeSavedPropertyLimit)} saved properties in total.
                  If you would like to begin your subscription early and gain access to the full {properties} saved property limit, <span onClick={this.handleUpgradeClick}>Click Here</span>.
                </div>
              )}
              {!overdueAccount ? null : (
                <div className={css.trial}>
                  Your subscription is currently past due and no new properties can be saved. If you would like to renew your subscription and gain access to the full {properties} saved property limit, <span onClick={this.handleUpgradeClick}>Click Here</span>.
                </div>
              )}
              {!fullAccount || overdueAccount ? null : (
                <div className={css.remaining}>
                  {remainingSavedProperties ? (
                    <div>You have {numberFormat(remainingSavedProperties)} saves remaining this billing period. Your save quantity will reset to {numberFormat(activeSavedPropertyLimit)} on {formatDateShort(moment(nextCycleDate))}.</div>
                  ) : (
                    <div className={css.trial}>
                      You have no remaining saved properties this billing period. Your save quantity will reset to {numberFormat(activeSavedPropertyLimit)} on {formatDateShort(moment(nextCycleDate))}.
                      {subUser ? <div>Please contact your team leader about increasing your property limit.</div> : (
                        <div>
                          {!catalogProducts || !catalogProducts.length ? `Please contact Customer Support at ${csPhone}.` : (
                            <div>To gain access to additional saved properties, <span onClick={this.handleUpgradeClick}>Click Here</span>.</div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {!nameMode ? null : (
            <form className={classNames(css.groupWidget, { [css.andAdd]: !!savedQuantity })} onSubmit={this.handleSubmit} key="bottom">
              <div className={css.buttons} style={{ visibility: modes.length > 1 ? 'visible' : 'hidden' }}>
                {modes.map((m, i) => <Radio name="mode" {...m} key={m.value} checked={mode === m.value} disabled={!i && !this.groups.length} onChange={this.handleChange} />)}
              </div>
              <div className={css.groupType}>
                {mode === 'new'
                  ? <input type="text" name="name" placeholder={`New ${groupType}`} value={name} className={css.input} onChange={this.handleChange} />
                  : <Dropdown name="name" options={this.groups.map(g => ({ value: String(g.id), label: g.displayName }))} value={String(selection.id)} onChange={this.handleSelectionChange} />
                }
              </div>
              {!checkOptions.length ? null : (
                <div className={css.checks}>
                  <div>
                    {checkOptions.map(o => <Checkbox label={o.label} name={o.id} key={o.id} checked={o.checked} defaultChecked={false} onClick={this.handleCheck} />)}
                  </div>
                </div>
              )}
              <div className={css.buttons}>
                <Button {...btn} onClick={closePopup}>Cancel</Button>
                <Button {...btn} kind={Button.kind.blue} type="submit">Save</Button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    );
  }
}

AddToGroup.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  size: PropTypes.number.isRequired,
  limit: PropTypes.number,
  name: PropTypes.string,
  onComplete: PropTypes.func,
  save: PropTypes.func,
  add: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  groupType: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  addPropertyMode: PropTypes.bool.isRequired,
  newEnabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  checkOptions: PropTypes.array,
};

AddToGroup.defaultProps = {
  groupType: 'Group',
  itemType: 'Property',
  addPropertyMode: false,
  newEnabled: true,
  checkOptions: [],
};

const AddToGroupPopup = connect((state, props) => ({
  loading: props.selectLoading(state),
  profile: selectProfile(state).toJS(),
}), {
  confirm: Confirm.open,
  openUpgradeAccount: UpgradeAccount.open,
})(AddToGroup);

AddToGroupPopup.open = props => openPopup(getPopupRegistration(AddToGroupPopup), { ...props, priority: Priority.MEDIUM_HIGH });

export default AddToGroupPopup;
