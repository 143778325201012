import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import pluralize from 'pluralize';

import { deferExecutor } from 'app/DeferredOnLocation';
import Confirm from 'app/components/Confirm';
import Button, { SolidButton } from 'components/Button';
import Link from 'components/Link';
import ToggleList from 'app/components/ToggleList';
import LeftPart from 'app/components/LeftPart';
import AddToGroup from 'app/components/AddToGroup';
import AddToFolder from 'app/components/AddToFolder';
import Prompt from 'app/components/Prompt';
import { SearchFilterViewerPopup } from 'app/components/SearchFilter';
import DropdownMenu from 'components/base/DropdownMenu';
import ContractManagerPopup from 'app/components/ContractManager';
import PostcardPreviewPopup from 'app/Campaigns/Postcard/preview';
import AppendJobEditor from 'app/Contacts/AppendJobEditor';
import ListAutomatorPopover from 'app/components/ListAutomatorPopover';
import ExportPropertyConfirmation from 'app/components/ExportPropertyConfirmation';
import mapFieldColumns, { PropertyAddressCellRenderer } from 'utils/grid';
import { isLaAvailable } from 'utils/brand';
import GridLayoutEditor from 'app/components/GridLayoutEditor';
import LaunchControlConfirmation from 'app/components/LaunchControlConfirmation';
import MailingLabelsPopup from 'app/components/MailingLabels';
import { MAIN_CONTEXT } from 'reducers';
import { selectPropertyGridLayout, selectListManagementEnabled, selectLayouts, selectFullAccessPermission } from 'data/user';
import { selectLoading as selectCampaignLoading, createSmsCampaign, selectSmsEnabled, selectSmsRegistered } from 'data/campaigns';
import {
  selectGroupContext,
  selectGroups,
  selectFavorites,
  selectLists,
  selectMonitoredLists,
  selectAlerts,
  selectLoading,
  selectFilters,
  selectFolders,
  loadGroupContext,
  updateGroupContext,
  exportGroupProperties,
  deletePropertyGroup,
  saveGroupProperties,
  PropertyGroupTypes,
  GroupListingTypes,
  GroupTypeNames,
  searchGroupProperties,
  getSavedPropertyDetail,
  selectSelection,
  setPropertySelection,
  clearPropertySelection,
  deleteGroupProperties,
  searchPropertyContacts,
  getGroupSearch,
  saveGroupSearch,
  selectSearches,
  savePropertyGroupFilter,
  deletePropertyGroupFilter,
  selectGroupCounts,
  getGroupPropertyCounts,
  savePropertyFolder,
  deletePropertyFolder,
} from 'data/property';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import Tile from './Tile';
import IndexCellRenderer from './indexCellRenderer';
import IndexHeaderRenderer from './indexHeaderRenderer';
import DetailCellRenderer from './detailCellRenderer';
import GridDateComponent from './gridDateComponent';
import PropertyImport from './Import';
import Options from './Options';
import PropertyGroupMap from './Map';
import css from './style.scss';

import { withRouter } from 'react-router-dom';
import { propertyGroupPath } from '../../routes';

const deleteWarning = 'Notice: Deleting properties does not provide access to more Saves. Access the Settings section to increase the Save limit.';

const groupTypeName = item => GroupTypeNames[typeof item === 'string' ? item : item.getIn(['info', 'type'], item.get('type'))];

// TODO: Importing these from app/routes cause the component to not hot-reload, probably due to co-dependency. Move functions to common location.
// const getPath = (root, path) => `${root}${path || path === 0 ? `/${path}` : ''}`;
// export const propertyGroupPath = path => getPath('/property/group', path);

// Function to extract only the date part from a date time string
function extractDate(dateString) {
  if (!dateString) return null; // Return null if dateString is not provided
  const dateOnly = dateString.split(' ')[0]; // Extract the date portion
  return dateOnly;
  }
  
  // Function to get filters from filter model and apply formatting if needed
  const getFilters = (filterModel = {}) => {
  // Iterate through each field in the filterModel
  return Object.keys(filterModel).reduce((ar, field) => {
      // Check if filterType is "date"
      if (filterModel[field].filterType === "date") {
          // If filterType is "date", apply extractDate method to format the value
          ar.push({ field, ...filterModel[field] , dateFrom : extractDate(filterModel[field].dateFrom) });
      } else {
          // If filterType is not "date", directly include the field and value
          ar.push({ field, ...filterModel[field] });
      }
      return ar;
  }, []);
  };
  
const getSingleRecordSelection = id => ({ allSelected: false, remainingSelected: false, selected: 1, selectedIds: [id], unselectedIds: [] });
const getListingTypeFilter = listingType => ({ field: 'types', filter: listingType, filterType: 'text', type: 'contains' });


let i = 0;
class PropertyGroup extends Component {
  constructor(props) {
    super(props);

    this.handleOpenContractManager = this.handleOpenContractManager.bind(this);
    this.handleAddGroup = this.handleAddGroup.bind(this);
    this.handleAddToFolder = this.handleAddToFolder.bind(this);
    this.handleAddFolder = this.handleAddFolder.bind(this);
    this.handleDeleteFolder = this.handleDeleteFolder.bind(this);
    this.handleEditFolder = this.handleEditFolder.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleRemoveDuplicates = this.handleRemoveDuplicates.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleDeleteDrop = this.handleDeleteDrop.bind(this);
    this.handleGroupDrop = this.handleGroupDrop.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.handleRename = this.handleRename.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleGridReady = this.handleGridReady.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.handleGridDetail = this.handleGridDetail.bind(this);
    this.handleGridModelUpdated = this.handleGridModelUpdated.bind(this);
    this.handleGridRowGroupOpened = this.handleGridRowGroupOpened.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleMarketingCellClicked = this.handleMarketingCellClicked.bind(this);
    this.handleViewFilter = this.handleViewFilter.bind(this);
    this.handleEditFilter = this.handleEditFilter.bind(this);
    this.handleEditGroupFilter = this.handleEditGroupFilter.bind(this);
    this.handleNewCampaign = this.handleNewCampaign.bind(this);
    this.handleTotalClick = this.handleTotalClick.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.search = this.search.bind(this);
    this.setActiveDetail = this.setActiveDetail.bind(this);
    this.handleGridLayoutApply = this.handleGridLayoutApply.bind(this);
    this.handleOpenMap = this.handleOpenMap.bind(this);
    this.handleGenerateMailingLabels = this.handleGenerateMailingLabels.bind(this);
    this.handleDetailPosition = this.handleDetailPosition.bind(this);
    this.positionDetailContent = this.positionDetailContent.bind(this);

    const { getSavedPropertyDetail, openPostcardPreview, lmEnabled, layout } = props;

    this.processingRows = false;
    this.activeDetail = null;
    this.lastSelectedId = null;

    const lmColumnSettings = {
      cellClass: ({ value }) => (value ? css.marketingCell : null),
      onCellClicked: this.handleMarketingCellClicked,
      hidden: !lmEnabled,
    };

    this.columns = layout.toJS().sections.reduce((ar, s) => ar.concat(s.fields.map(({ id, name, path, width, pin, hidden, type }) => ({ group: s.name, name, width, pin, hidden, path, type, lmRequired: s.code === 'MARKETING' && id !== 584 }))), []);

    this.columnPresets = {
      campaignQuantity: lmColumnSettings,
      voicemailCampaignQuantity: lmColumnSettings,
      postcardCampaignQuantity: lmColumnSettings,
      emailCampaignQuantity: lmColumnSettings,
      contactAppendQuantity: lmColumnSettings,
      listQuantity: {
        ...lmColumnSettings,
        hidden: false,
        cellClass: ({ value }) => (value ? css.marketingCell : null),
      },
    };
    

    this.gridContext = {
      onDragStart: this.handleDragStart,
      onDragEnd: this.handleDragEnd,
      onSelect: this.handleSelect,
      setActiveDetail: this.setActiveDetail,
      getSavedPropertyDetail,
      openPostcardPreview,
      groupId: 0,
      detailExpandField: null,
      search: this.search,
    };

    const { layoutId, columnDefs } = this.getColumnInfo(props);

    this.gridProps = {
      onGridReady: this.handleGridReady,
      onModelUpdated: this.handleGridModelUpdated,
      onRowGroupOpened: this.handleGridRowGroupOpened,
      rowModelType: 'serverSide',
      getRowNodeId: d => d.id,
      infiniteInitialRowCount: 1500,
      cacheBlockSize: 500,
      blockLoadDebounceMillis: 150,
      maxConcurrentDatasourceRequests: 2,
      suppressCellSelection: true,
      enableCellTextSelection: true,
      defaultColDef: { resizable: true, sortable: true, menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    },
      columnDefs,
      detailRowHeight: 330,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            { headerName: 'ID', field: 'id', width: 50 },
            { headerName: 'Test', field: 'test', width: 55 },
          ],
        },
        getDetailRowData: this.handleGridDetail,
        components: {
          detailCellRenderer: DetailCellRenderer,
        },
      },
      serverSideStoreType: 'partial',
      masterDetail: true,
      isRowMaster: () => true, // d.listQuantity > 1 || (lmEnabled && !!d.campaignQuantity),
      // keepDetailRows: true, Only disabling because the tab auto-selection won't work the second time it's opened, if opening via clicking the marketing columns.
      keepDetailRowsCount: 1000,
      detailCellRenderer: 'detailCellRenderer',
      frameworkComponents: {
        indexCellRenderer: IndexCellRenderer,
        indexHeaderRenderer: IndexHeaderRenderer,
        propertyAddressCellRenderer: PropertyAddressCellRenderer,
        detailCellRenderer: DetailCellRenderer,
        gridDateComponent: GridDateComponent,
      },
      context: this.gridContext,
      dateComponent: 'gridDateComponent',
      getContextMenuItems: this.getContextMenuItems,
    };

    // eslint-disable-next-line no-param-reassign,no-return-assign
    // this.gridProps.columnDefs.forEach(c => c.headerTooltip = c.headerName);

    this.state = {
      loadId: null,
      layoutId,
      search: false,
      dragGroup: null,
      dragData: null,
      selectedTotal: '',
      selectedFilter: null,
      columnApi: null,
      listManagementUpgradeVisible: false,
      topRecords: [],
      isDropActive: false
    };
  }

  componentWillMount() {
    i = 0;

    this.loadGroup(this.props);
  }

  componentWillReceiveProps(props) {
    if (props.match.params?.groupId !== this.props.match.params?.groupId &&  this.props.match.params?.groupId) {
      this.loadGroup(props);
    }
  }
  componentWillUnmount() {
    this.setState({
      loadId: null,
      search: false,
      dragGroup: null,
      dragData: null,
      selectedTotal: '',
      selectedFilter: null,
      columnApi: null,
      listManagementUpgradeVisible: false,
      topRecords: [],
    })
  }


  getGridSearch() {
    const { id, name, selection } = this.props;
    const { selectedFilter, selectedTotal, columnApi } = this.state;
    const { items, allSelected, remainingSelected, selected } = selection.toJS();

    // Get columns in visual order, with pin position factored in.
    let cols = columnApi.getAllGridColumns().filter(c => c.visible && !['id', 'resultIndex'].includes(c.colDef.field));
    cols = cols.filter(c => c.pinned === 'left').concat(cols.filter(c => !c.pinned)).concat(cols.filter(c => c.pinned === 'right'));

    let sortModel = []
    const sortCol = columnApi?.getAllGridColumns()?.find((res) => res?.sort)
    if(sortCol && sortCol?.colId) {
      sortModel = [{colId: sortCol.colId, sort: sortCol.sort}]
    }


   const regex = /property\/group\/(\d+)/;
   const matches = location.pathname.match(regex);
   let groupId = matches && matches.length > 0 ? matches[1] : 0;
   let getLatestid = id != groupId ? groupId : id;
   this.gridContext.groupId = groupId;

    const search = {
      id : getLatestid,
      name,
      filters: getFilters(this.api.getFilterModel()),
      sortModel,
      allSelected,
      remainingSelected,
      selected,
      automationStatus: selectedTotal === '' ? null : !!Number(selectedTotal),
      valueCols: cols.map(({ colDef: { field, headerName, actualWidth } }) => ({ colId: field, headerName, width: actualWidth })),
      selectedIds: allSelected ? [] : Object.keys(items).filter(id => items[id]),
      unselectedIds: allSelected ? [] : Object.keys(items).filter(id => !items[id]),
    };

    if (selectedFilter) search.filters.push(getListingTypeFilter(selectedFilter));

    return search;
  }

  getFixedColumns() {
    return [
      { field: 'id', width: 20, filter: false, cellRenderer: 'agGroupCellRenderer', pinned: 'left', sortable: false, suppressMovable: true },
      { headerName: '', field: 'resultIndex', width: 60, pinned: 'left', filter: 'agNumberColumnFilter', headerComponent: 'indexHeaderRenderer', filterParams: { defaultOption: 'lessThanOrEqual', suppressAndOrCondition: true }, cellRenderer: 'indexCellRenderer', sortable: false, suppressMovable: true },
      { headerName: 'Target List', field: 'groupName', width: 175, pinned: 'left', filter: 'agTextColumnFilter', suppressMovable: true, hide: this.props.id !== 1 || this.state.selectedTotal === '' },
    ];
  }

  getColumnInfo(props, newLayout) {
    const { layouts, context } = props;

    let layout = newLayout;
    if (!layout) {
      const id = context.getIn(['info', 'gridLayoutId']);
      layout = layouts.find(l => l.id === id) || layouts.find(l => l.defaultLayout);
    }


    const fieldMap = layout && Array.isArray(layout.fields) ? 
          layout.fields.reduce((m, f) => {
            if (f && f.name) {
              return { ...m, [f.name]: f };
            }
            return m;
          }, {}) : {};

    const columns = this.columns.map((c) => {
      const { name, seq, pin, width, hidden } = fieldMap[c.path] || {};

      return { ...c, ...(name ? { seq, pin, width, hidden } : {}) };
    }).sort((a, b) => a.seq - b.seq);

    const columnDefs = this.getFixedColumns().concat(mapFieldColumns(columns, this.columnPresets));

    return { columnDefs, layoutId: layout ? layout.id : null };
  }

  setLayout(props, layout, searchReset = false) {
    const { layoutId, columnDefs } = this.getColumnInfo(props, layout);

    this.setState({ layoutId }, () => {
      if (this.api) {
        this.api.setColumnDefs([]); // Required workaround for bug: https://github.com/ag-grid/ag-grid/issues/2889
        this.api.setColumnDefs(columnDefs);
      }

      this.search(searchReset);
    });
  }

  setActiveDetail(detail) {
    this.activeDetail = detail;
    this.gridContext.detailExpandField = null;
  }

  loadGroup(props = this.props) {
    const { history, loadGroupContext, groups, context} = props;
    const { selectedTotal, columnApi } = this.state;

    const regex = /property\/group\/(\d+)/;
    const matches = location.pathname.match(regex);
    let groupId = matches && matches?.length > 0 ? matches[1] : 0;
    this.gridContext.groupId = groupId;
    
    const loadId = Number(groupId) || 0;
      this.setState({
        loadId,
      })

    if (groups.size && i++ < 1000) {
      const group = groups.find(g => g.get('id') === loadId);

      if (!group) history.push(propertyGroupPath(0));
      else {
        if (columnApi) columnApi.setColumnVisible('groupName', loadId === 1 && selectedTotal !== '');
        if (!group.get('monitored') && selectedTotal !== '') this.setState({ selectedTotal: '' });

        // Update context to one that should be loaded.
        if (!context || context.get('id') !== loadId) {
          loadGroupContext(MAIN_CONTEXT, loadId, { filterEnabled: true });
        }

        // Correct context is loaded, now load proper layout and run search for this group
        else if (this.state.loadId !== loadId && context.get('size') == null) this.setState({ loadId }, () => this.setLayout(this.props, null, true));

        else if (context.get('size') != null) this.setState({ loadId: null });
      }
    }
  }

  confirmContactDeletion(quantity, callback) {
    this.props.confirm({
      caption: 'Confirm Contact Deletion',
      okLabel: 'Yes',
      cancelLabel: 'No',
      question: `Do you want to delete any contacts associated with ${quantity === 1 ? 'this property' : 'these properties'}?`,
      onOk: () => callback(true),
      onCancel: () => callback(false),
    });
  }

  search(reset = true) {
    if (this.api) {
      const { id } = this.props;
      this.lastSelectedId = null;

      const search = () => {
        this.gridContext.groupId = id;
        this.api.setServerSideDatasource({
          getRows: ({ request, failCallback, successCallback }) => {
            const { searchGroupProperties, groupFilters, getGroupPropertyCounts } = this.props;
            const { selectedTotal } = this.state;
            const { startRow, endRow } = request;

             const getGridSearchFilter = this.getGridSearch()
            searchGroupProperties(
              { ...this.getGridSearch(), startRow, endRow },
              id && groupFilters[selectedTotal].dirty,
              ({ response }) => {
                const { properties = [] } = response;
                successCallback(properties, (properties.length && properties[0].count) || 0, true);
                getGroupPropertyCounts(getGridSearchFilter?.id);
                if (!startRow) this.setState({ topRecords: properties });
              },
              failCallback,
            );
          },
        });
      };

      if (!reset) search();
      else {
        this.api.setFilterModel(null);
        this.props.clearPropertySelection();
        this.setState({ selectedTotal: '', selectedFilter: null, topRecords: [] }, search);
      }
    }

  }

  updateSelection(items, remainingSelected) {
    const { setPropertySelection } = this.props;
    const totalRows = this.api.getDisplayedRowCount();
    const keys = Object.keys(items);
    const selected = keys.filter(i => items[i]).length + (remainingSelected ? totalRows - keys.length : 0);
    const allSelected = totalRows && selected === totalRows;

    setPropertySelection({ items, allSelected, selected: allSelected ? totalRows : selected, remainingSelected: allSelected || remainingSelected });
  }

  handleGridLayoutApply(layout) {
    this.setLayout(this.props, layout);
  }

  handleNewCampaign(type) {
    const { searchPropertyContacts, createSmsCampaign, openAppendJobEditor, history, alert, context, openLaunchControlConfirmation, confirm } = this.props;
    const search = this.getGridSearch();

    if (!search.selected) alert('Please select the record(s) you would like to market to.');
    else if (type === 'SMS') {
      // Confirm with user whether to submit selected lead(s)
      confirm({
        caption: 'Send SMS (Beta)',
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        question: `You are about to submit ${pluralize('property', search.selected, true)} to Launch Control for Texting`,
        onOk: () => {
          // User agreed, submit to server
          createSmsCampaign(search, ({ response: { redirectUrl, leadQuantity } }) => {
            // Successful response; open confirmation dialog if any were successfully sent, error message otherwise.
            if (leadQuantity) openLaunchControlConfirmation({ redirectUrl, leadQuantity });
            else alert('Oops, looks like the properties you selected do not have mobile numbers linked. *Only properties that are linked with mobile phone numbers are successfully transferred.');
          });
        },
      });
    } else {
      searchPropertyContacts(search, ({ response: contactIds }) => {
        if (type === 'APPEND') openAppendJobEditor({ contactIds });
        else if (type === 'POSTCARD') history.push({ pathname: '/campaign/0/postcard/0', state: { contactIds, savedPropertyGroupId: context.get('id') } });
      });
    }
  }

  handleGenerateMailingLabels() {
    const { alert, openMailingLabels } = this.props;
    const propertySearch = this.getGridSearch();

    if (!propertySearch.selected) alert('Please select the record(s) you would like to print.');
    else openMailingLabels({ propertySearch });
  }

  handleSelect(id, selected, ev) {
    const { selection, searchGroupProperties } = this.props;
    const items = selection.get('items').toJS();

    // If no ID is provided then it's a select all. Toggle all items to indicated status.
    if (!id) Object.keys(items).forEach((i) => { items[i] = selected; });
    else {
      // If this is not a range selection (range = shift pressed), the item selected (potential beginning of range), or clicking the last selected item again, then toggle single item.
      if (!ev.shiftKey || !this.lastSelectedId || id === this.lastSelectedId) items[id] = selected;
      else {

        const startId = Math.min(id, this.lastSelectedId);
        const endId = Math.max(id, this.lastSelectedId);
  
        // Iterate through rows and toggle the selection status of any whose row index is within range
        Object.keys(items).forEach((i) => {
          if (i >= startId && i <= endId) {
            items[i] = selected;
          }
        });  
      }

      // Record last selected item's ID, potentially to be referenced as the beginning of a range selection.
      this.lastSelectedId = id;
    }

    this.updateSelection(items, (!id && selected) || (id && selection.get('remainingSelected')));
  }

  handleGridModelUpdated() {
    if (this.api) {
      const { selection } = this.props;
      const items = selection.get('items').toJS();
      const remainingSelected = selection.get('remainingSelected');

      // Update any newly-retried records with the "remainingSelected" selection status. Represents the "select all" for non-loaded records,
      // which generally won't have their ID in the current selection map (but can possibly if there was a big range selection).
      this.api.forEachNode(({ data: { id } = {} }) => {
        if (id) items[id] = id in items ? items[id] : remainingSelected;
      });

      this.updateSelection(items, remainingSelected);
    }
  }

  handleMarketingCellClicked(params) {
    const { value, node, colDef: { field } } = params;
    if (value) {
      if (!node.expanded) {
        this.gridContext.detailExpandField = field;
        node.setExpanded(true);
      } else if (this.activeDetail) this.activeDetail.loadField(field);
    }
  }

  handleGridRowGroupOpened(params) {
    if (this.processingRows || !params.node.expanded) return;

    this.processingRows = true;
    this.api.forEachNode((node) => { if (node.expanded && node.data.id !== params.data.id) node.setExpanded(false); });
    this.processingRows = false;
  }

  handleGridDetail(params) {
    const { data: { id }, successCallback } = params;

    this.props.getSavedPropertyDetail(id, ({ response }) => { successCallback(response); });
  }

  getContextMenuItems() {
    const result = [
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      {
        name: 'Export',
        subMenu: [
          {
            name: 'CSV Export',
            action: () => {
              this.handleExport(true);
            },
            icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
          },
          {
            name: 'Excel Export (.xlsx)',
            action: () => {
              this.handleExport(false);
            },
            icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
          },
          {
            name: 'Excel Export (.xml)',
            action: () => {
              this.handleExport(true, true);
            },
            icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
          },
        ],
        icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
      },

    ];

    return result;
  }

  handleOpenContractManager() {
    this.props.openContractManager();
  }

  handleImport() {
    this.props.openImport();
  }

  handleViewFilter(editMode = false) {
    const { openSearchFilterViewer, saveGroupSearch, getGroupSearch, id, monitored, lmEnabled } = this.props;

    getGroupSearch(id, ({ response }) => {
      openSearchFilterViewer({
        search: response,
        editMode,
        editEnabled: lmEnabled && monitored,
        onSave: (search, onSuccess) => {
          const s = search.toJS();
          const type = s.listingType;

          saveGroupSearch(id, { ...s, listingType: Array.isArray(type) ? type[0] : type }, () => {
            onSuccess();
            this.search(false);
          });
        },
      });
    });
  }

  handleEditFilter() {
    this.handleViewFilter(true);
  }

  handleEditGroupFilter(filterType) {
    const { openSearchFilterViewer, savePropertyGroupFilter, deletePropertyGroupFilter, id, groupFilters } = this.props;
    const { selectedTotal } = this.state;

    const { filter, customFilter } = groupFilters[selectedTotal].filters.find(f => f.type === filterType);

    openSearchFilterViewer({
      search: customFilter || filter,
      editMode: true,
      summaryMode: true,
      onSave: (search, onSuccess) => {
        const s = search.toJS();
        const type = s.listingType;

        return savePropertyGroupFilter({
          ...s,
          filterType,
          savedPropertyGroupId: id,
          listingType: Array.isArray(type) ? type[0] : type,
        }, () => {
          onSuccess();
          this.search(false);
        });
      },
      onDelete: !customFilter ? null : (onSuccess) => {
        // Deletes the filter if customFilter exists, and then calls onSuccess
        deletePropertyGroupFilter(customFilter.id, () => {
          onSuccess();                // Executes the onSuccess callback after deleting
          this.search(false);         // Triggers a search or refreshes results
        });
      },
    });
  }

  handleRemove(selection = {}) {
    const { confirmRemoval, deleteGroupProperties, context, id } = this.props;
    const search = { ...this.getGridSearch(), ...selection };

    const qty = search.selected;

    confirmRemoval({
      itemType: 'Property',
      question: `Are you sure you want to remove the ${pluralize('selected property', qty, qty > 1)} from ${!id ? 'all assigned groups and marketing lists' : `this ${groupTypeName(context).toLowerCase()}`}?`,
      warning: deleteWarning,
      onOk: () => this.confirmContactDeletion(qty, deleteContacts => deleteGroupProperties(search, deleteContacts, false, () => this.search(false))),
    });
  }

  handleRemoveDuplicates() {
    const { confirmRemoval, deleteGroupProperties, id } = this.props;
    const search = { ...this.getGridSearch() };

    confirmRemoval({
      itemType: 'Property',
      question: id ? 'Notice - By removing duplicate properties, all Properties selected in this list will remain and any duplicate property in other lists will be deleted. Once deleted it cannot be recovered unless re-saved.' : 'Notice - By removing duplicate properties, The first list a property was saved to will remain and all other duplicates will be deleted. Once deleted it cannot be recovered unless re-saved.',
      warning: deleteWarning,
      onOk: () => deleteGroupProperties(search, true, true, () => this.search(false)),
    });
  }

  handleDeleteGroup(id) {
    const { confirmRemoval, groups, context, deletePropertyGroup , history } = this.props;
    const group = groups.find(g => g.get('id') === (id || context.get('id')));

    const qty = group.get('size');
    const itemType = groupTypeName(group);

    const regex = /property\/group\/(\d+)/;
    const matches = location.pathname.match(regex);
    let groupId = matches && matches.length > 0 ? matches[1] : null;

    const doDelete = (deleteContacts = false) => {
      deletePropertyGroup(group.get('id'), deleteContacts, null, ({ response: { groups } }) => {        
      if (Number(groupId) === group.get('id')) {
         history.push(propertyGroupPath(0))
      }
      })
    } 

    confirmRemoval({
      itemType,
      question: `Are you sure you want to remove ${itemType.toLowerCase()} '${group.get('name')}'?${qty ? ` The ${qty === 1 ? 'property' : `${qty} properties`} under this ${itemType.toLowerCase()} will be removed.` : ''}`,
      warning: deleteWarning,
      onOk: () => (!qty ? doDelete() : this.confirmContactDeletion(qty, doDelete)),
    });
  }

  handleDeleteDrop() {
    const { confirmRemoval, deletePropertyGroup } = this.props;
    const { dragGroup: { id, name }, dragData: { listingType, quantity, recordId, type, key, selected } } = this.state;

    if (listingType) {
      const { label, value } = listingType;
      confirmRemoval({
        question: `Are you sure you want to remove the ${pluralize(`${label} record`, quantity, quantity > 1)} from '${name}'?`,
        warning: deleteWarning,
        onOk: () => this.confirmContactDeletion(quantity, deleteContacts => deletePropertyGroup(id, deleteContacts, value)),
      });
    } else if (recordId) this.handleRemove(selected ? {} : getSingleRecordSelection(recordId));
    else if (type === 'FOLDER') this.handleDeleteFolder(key);
    else this.handleDeleteGroup(id);
  }

  handleGroupDrop(targetId, type) {
    const { groups, confirm } = this.props;
    const { dragGroup: { id, name: groupName, size: groupSize }, dragData: { listingType: { label, value: listingType } = {}, quantity: size, recordId, selected } } = this.state;
    const name = targetId ? groups.find(g => g.get('id') === Number(targetId)).get('name') : null;

    let text;
    let search;

    // Property drag
    if (recordId) search = selected ? this.getGridSearch() : getSingleRecordSelection(recordId);

    // Group drag
    else if (!listingType) search = { selected: groupSize, allSelected: true };

    // Listing Type drag (otherwise it's a group drag)
    else {
      text = ` ${label} record`;
      search = { filters: [getListingTypeFilter(listingType)], selected: size, allSelected: true };
    }

    search.id = id;
    search.name = groupName;
    search.records = pluralize(text || `${selected ? 'selected ' : ''}property`, search.selected, search.selected > 1);

    text = `${search.records}${text || recordId ? '' : ` from '${groupName}'`}`;
    text = targetId ? `Add the ${text} to '${name}'?` : `The ${text} will be added to this new ${groupTypeName(type)}.`;

    if (!targetId) this.handleAddGroup(type, search, text);
    else {
      confirm({
        caption: pluralize('Add Property', search.selected),
        okLabel: 'Yes',
        cancelLabel: 'No',
        question: text,
        checkOptions: search.id < 100 ? null : [{ id: 'delete', label: `Delete the ${search.records} from '${groupName}'.` }],
        onOk: ({ checkOptions }) => this.saveGroup(search, type, name, !!(checkOptions.length && checkOptions[0].checked)),
      });
    }
  }

  handleDragStart(dragData = {}) {
    const { groups, context } = this.props;
    const { id, name, size } = groups.find(g => g.get('id') === ((dragData.type === 'ITEM' && dragData.id) || context.get('id'))).toJS();

    // Store the source drag data, which describes the item being dragged, as well as details of the pertinent group. If a group is being dragged,
    // then grab its info. If not, then use the currently selected group. This group info is used to prevent dropping items into the same group or parent group.
    this.setState({ dragData, dragGroup: { id, name, size }, isDropActive: true });
  }

  handleDragEnd() {
    this.setState({ dragGroup: null, dragData: null, isDropActive: false });
  }

  handleExport(csv, isXML = false) {
    const { exportGroupProperties, context, openExportPropertyConfirmation } = this.props;
    exportGroupProperties(this.getGridSearch(), context.getIn(['info', 'name']), csv, isXML, ({ response }) => {
      response.isXML = isXML;
      openExportPropertyConfirmation({ exportResponse: response });
    });
  }

  handleRename() {
    const { context, prompt, saveGroupProperties } = this.props;

    prompt({
      text: 'Please enter a new name for this group.',
      title: 'Rename Group',
      value: context.getIn(['info', 'name']),
      onSubmit: name => saveGroupProperties({ id: context.get('id'), name }),
    });
  }

  handleTotalClick(type) {
    const { selectedTotal, selectedFilter } = this.state;
    if (type !== selectedTotal || !!selectedFilter) this.setState({ selectedTotal: type, selectedFilter: type === selectedTotal ? null : selectedFilter }, () => this.search(false));
  }

  handleFilterClick(type) {
    const { selectedFilter } = this.state;
    this.setState({ selectedFilter: selectedFilter === type ? null : type }, () => this.search(false));
  }

  handleAddGroup(type, search, text) {
    this.props.prompt({
      title: `Add New ${groupTypeName(type)}`,
      text,
      label: 'Name',
      checkOptions: !search || search.id < 100 ? null : [{ id: 'delete', label: `Delete the ${search.records} from '${search.name}'.` }],
      onSubmit: (name, { checkOptions }) => this.saveGroup(search, type, name, !!(checkOptions.length && checkOptions[0].checked)),
    });
  }

  handleAddToGroup(type) {
    const { id, name, alert, groups, openAddToGroup } = this.props;
    const search = this.getGridSearch();
    const { selected: size, automationStatus } = search;
    const monitored = type === PropertyGroupTypes.MONITORED;

    if (!size) alert('Please select the record(s) you would like to add.');
    else {
      openAddToGroup({
        groupType: groupTypeName(type),
        selectLoading,
        size,
        itemType: 'Property',
        newEnabled: !monitored,
        name: automationStatus ? name : null,
        checkOptions: id < 100 ? null : [{ id: 'delete', label: `Delete the selected ${pluralize('property', size, size > 1)} from '${name}'.` }],
        groups: groups.filter(g => (automationStatus || g.get('id') !== id) && g.get('type') === type && !g.get('standard')),
        add: (name, afterSuccess, { selection, checkOptions }) => this.saveGroup(search, monitored ? selection.originalType : type, name, !!(checkOptions.length && checkOptions[0].checked), () => afterSuccess(size)),
      });
    }
  }

  handleAddFolder(type) {
    const { openAddToFolder, folders, savePropertyFolder } = this.props;

    openAddToFolder({
      folders: folders.toJS(),
      type,
      editMode: true,
      onSubmit: folder => savePropertyFolder(folder),
    });
  }

  handleAddToFolder() {
    const { openAddToFolder, folders, context, savePropertyFolder } = this.props;

    const { id, type, folderKey } = context.get('info').toJS();

    openAddToFolder({
      folders: folders.toJS(),
      type,
      folderKey,
      onSubmit: folder => savePropertyFolder(folder, id),
    });
  }

  handleDeleteFolder(key) {
    const { context, deletePropertyFolder, confirmRemoval, folders } = this.props;
    const folderKey = key || context.getIn(['info', 'folderKey']);
    const folder = folders.find(f => f.get('key') === folderKey);

    confirmRemoval({
      itemType: 'Folder',
      question: `Are you sure you want to remove the "${folder.get('name')}" folder? This will not delete any of your lists or saved properties.`,
      onOk: () => deletePropertyFolder(folderKey.substr(1)),
    });
  }

  handleEditFolder(key) {
    const { openAddToFolder, folders, context, savePropertyFolder } = this.props;
    const folderKey = key || context.getIn(['info', 'folderKey']);
    const folder = folders.find(f => f.get('key') === folderKey);

    openAddToFolder({
      folders: folders.toJS(),
      type: folder.get('type'),
      folderKey,
      editMode: true,
      onSubmit: folder => savePropertyFolder(folder),
    });
  }

  handleGridReady({ api, columnApi }) {
    this.api = api;
    this.gridContext.api = api;
    this.search();
    this.setState({ columnApi });
    this.handleDetailPosition();
  }

  handleOpenMap() {
    this.props.openMap({ properties: this.state.topRecords, groupName: this.props.name });
  }

  saveGroup(search, type, name, deleteOriginal = false, afterSuccess) {
    const { saveGroupProperties, history, id } = this.props;
    saveGroupProperties({ type, name }, search, deleteOriginal, ({ response: { groups } }) => {
      if (afterSuccess) afterSuccess();

      // Manually trigger a grid refresh if the same group is being modified; it will happen automatically if switching groups.
      if (id === groups.find(g => g.modified).id) this.search(false);
      else history.push(propertyGroupPath(0));
    });
  }

  positionDetailContent(mutationsList) {
    for (var mutation of mutationsList) {
      if (mutation.type == 'childList') {
        if(document.getElementsByClassName('ag-full-width-container')[1].childNodes.length>0) {
          var rowIndex = -1;
          this.api.forEachNode((node) => { 
            if(node.expanded) {
               rowIndex = node.rowIndex;
            }
          });
          var position = (rowIndex + 1) *27;
          var transform = "translateY("+position+"px)";
          document.getElementsByClassName('ag-full-width-container')[1].firstChild.style.transform=transform;
        }
      }
    }
  };

  handleDetailPosition(){
    var targetNode = document.getElementsByClassName('ag-full-width-container');
    var config = {childList: true};
    var observer = new MutationObserver(this.positionDetailContent);
    observer.observe(targetNode[1], config);
  }

  render() {
    const { children, context = Map(), monitoredLists, loading, selection, groupFilters, id, name, type, groups, lmEnabled, monitored, layouts, folders, fullAccess, smsRegistered } = this.props;
    const { dragGroup, dragData, selectedTotal, selectedFilter, columnApi, layoutId, topRecords , loadId, isDropActive } = this.state;

    if(id !== loadId) {
      if(this.api) {
        this.setState({
          loadId : id
        })
        this.api.refreshServerSideStore({ purge: false})
      }
    }

    const selected = selection.get('selected');
    const customGroup = id >= 10;
    const groupType = groupTypeName(context);
    const all = selectedTotal === '';
    const add = selectedTotal === '1';
    const remove = selectedTotal === '0';
    const mobile = type === PropertyGroupTypes.MOBILE;
    const folderEnabled = customGroup && !mobile;
    const { size, addPropertyQuantity, removePropertyQuantity, folderKey } = context.get('info').toJS();

    const totals = [{ type: '', name: 'Total', quantity: size }];
    const laAvailable = isLaAvailable();

    if (lmEnabled && monitored) {
      if (addPropertyQuantity) totals.push({ type: '1', name: 'Add', quantity: addPropertyQuantity, up: true });
      if (removePropertyQuantity) totals.push({ type: '0', name: 'Removals', quantity: removePropertyQuantity, down: true });
    }

    const tileProps = {
      onEdit: this.handleEditGroupFilter,
      // onDragStart: dragStartWrapper(() => this.handleDragStart({ listingType: type, quantity })),
    };

    const baseToggleProps = {
      location: propertyGroupPath(0),
      sizeProperty: 'size',
      selectedId: id,
      draggable: true,
      onGroupDrop: this.handleGroupDrop,
      onDragStart: this.handleDragStart,
      onDragEnd: this.handleDragEnd,
      dragGroup,
      dragData,
      isDropActive
    };

    const toggleProps = {
      ...baseToggleProps,
      folderDraggable: true,
      allowDrop: true,
      allowAddDrop: true,
      onDeleteFolderClick: this.handleDeleteFolder,
      onEditFolderClick: this.handleEditFolder,
    };

    const monitorTransferLists = groups.filter(g => g.get('type') !== PropertyGroupTypes.MOBILE && g.get('id') !== id);
    const mobileLists = groups.filter(g => g.get('type') === PropertyGroupTypes.MOBILE);
    const toggleList = (type, data, props = toggleProps) => (
      <ToggleList
        {...props}
        type={type}
        folders={folders.filter(f => f.get('type') === type)}
        data={data || groups.filter(g => g.get('type') === type)}
        addItems={!data && [
          { type, label: `Add ${GroupTypeNames[type]}`, onClick: () => this.handleAddGroup(type) },
          { type, label: 'Add Folder', onClick: () => this.handleAddFolder(type) },
        ]}
      />
    );

    return (
      <div className={`${css.groups} ${css.groupsContainer}`}>
        <div className={css.left}>
          <LeftPart caption="My Properties" onDeleteDrop={this.handleDeleteDrop} allowDrop>
            {toggleList(PropertyGroupTypes.FAVORITE)}
            {toggleList(PropertyGroupTypes.MARKETING)}
            {!monitoredLists.size ? null : toggleList(PropertyGroupTypes.MONITORED, monitoredLists, { ...toggleProps, allowAddDrop: false, onAddClick: () => this.handleAddFolder(PropertyGroupTypes.MONITORED), addTitle: 'Add Folder' })}
            {!mobileLists.size ? null : toggleList(PropertyGroupTypes.MOBILE, mobileLists, { ...baseToggleProps, caption: 'Mobile' })}
          </LeftPart>
        </div>
        <div className={css.rightSide}>
          <header className={css.listingTypes}>
            <div className={css.typeContainer}>
              {totals.map(t => <Tile {...tileProps} {...t} selected={selectedTotal === t.type} onClick={this.handleTotalClick} key={t.type} />)}
            </div>
            <div className={css.typeContainer} style={{ marginLeft: 0 }}>
              {groupFilters[selectedTotal].filters.filter(t => laAvailable || !t.disabled).map(t => <Tile {...tileProps} {...t} selected={selectedFilter === t.type} onClick={this.handleFilterClick} custom={!!t.customFilter} key={t.type} />)}
              <div className={css.padding} />
            </div>
            {lmEnabled ? null : <ListAutomatorPopover className={css.lmOverlay} />}
          </header>
          <section className={css.dashboard}>
            <div className={css.properties}>
              <div className={css.header}>
                <div className={css.controls}>
                  {!add ? null : <SolidButton loading={loading} text={`Add Selected ${pluralize('Record', selected)}`} onClick={() => this.handleAddToGroup(type)} disabled={!selected} />}
                  {!remove ? null : <Button loading={loading} text={`Delete Selected ${pluralize('Record', selected)}`} onClick={() => this.handleRemove()} disabled={!selected} red />}
                  {!all || mobile || !laAvailable ? null : [
                    <SolidButton disabled={!lmEnabled} loading={loading} text="Import List" icon="iconUpload" onClick={this.handleImport} className={css.importButton} />,
                    lmEnabled ? null : <ListAutomatorPopover className={css.lmOverlay} />,
                  ]}
                  {add || mobile ? null : <SolidButton loading={loading} disabled={!selected} text="Export" icon="iconFileText" onClick={() => this.handleExport(false)} />}
                  <DropdownMenu
                    text="Actions"
                    icon="iconCheckSquare"
                    loading={loading}
                    leftAnchored
                    items={[
                      selected && !remove ? { label: `Delete ${pluralize('Record', selected)}`, onSelect: this.handleRemove } : null,
                      // selected && !remove ? { label: 'Delete Duplicate Properties', onSelect: this.handleRemoveDuplicates } : null,
                      { label: 'Add to Favorites List', onSelect: () => this.handleAddToGroup(PropertyGroupTypes.FAVORITE) },
                      { label: 'Add to Marketing List', onSelect: () => this.handleAddToGroup(PropertyGroupTypes.MARKETING) },
                      monitoredLists.size ? { label: 'Add to Automated List', onSelect: () => this.handleAddToGroup(PropertyGroupTypes.MONITORED) } : null,
                      !folderEnabled ? null : { label: `${folderKey ? 'Move' : 'Add'} to Folder`, onSelect: this.handleAddToFolder },
                      !folderEnabled || (folderKey || '') === '' ? null : { label: 'Remove Folder', onSelect: () => this.handleDeleteFolder() },
                      !folderEnabled || (folderKey || '') === '' ? null : { label: 'Edit Folder', onSelect: this.handleEditFolder },
                      !mobile && customGroup && !context.getIn(['info', 'defaultGroup']) ? { label: `Rename ${groupType}`, onSelect: this.handleRename } : null,
                      !mobile && customGroup ? { label: `Delete ${groupType}`, onSelect: () => this.handleDeleteGroup() } : null,
                      mobile ? null : { label: 'Manage Contracts', onSelect: this.handleOpenContractManager },
                      mobile || !topRecords.length ? null : { label: 'View Map', onSelect: this.handleOpenMap },
                      !mobile && !add && selected ? { label: 'Export CSV', onSelect: () => this.handleExport(true) } : null,
                      customGroup && lmEnabled && monitored ? { label: 'Edit List Filters', onSelect: this.handleEditFilter } : null,
                      { label: 'Generate Mailing Labels', onSelect: this.handleGenerateMailingLabels },
                    ]}
                  />
                  {!all || !fullAccess || mobile ? null : (
                    <DropdownMenu
                      text="New Campaign"
                      icon="iconCampaigns"
                      loading={loading}
                      items={[
                        { label: 'Postcard Campaign', onSelect: () => this.handleNewCampaign('POSTCARD') },
                        // { label: 'Email Campaign', onSelect: () => this.handleNewCampaign('EMAIL') },
                        { label: 'Skip Tracing', onSelect: () => this.handleNewCampaign('APPEND') },
                        !smsRegistered || !customGroup ? null : { label: 'SMS (Beta)', onSelect: () => this.handleNewCampaign('SMS') },
                      ]}
                    />
                  )}
                  {!id || !lmEnabled || !all || mobile ? null : <Options loading={loading} lists={monitorTransferLists} context={context} />}
                </div>
                <div className={css.caption}>
                  <div className={css.titleContainer}>
                    <div className={css.title}>{name}</div>
                    <div className={css.titleQuantity}>({size})</div>
                  </div>
                  <div className={css.bottom}>
                    {!selected ? <div /> : <div className={css.selected}>{selected} Selected</div>}
                    {!customGroup ? null : <Link className={css.editLink} onClick={() => this.handleViewFilter()}>View List Filters</Link>}
                  </div>
                </div>
                <div className={css.gridOptions}>
                  <GridLayoutEditor
                    fixedColumns={this.getFixedColumns()}
                    columns={this.columns.map(c => ({ ...c, field: c.path, unavailable: c.lmRequired && !lmEnabled }))}
                    onApply={this.handleGridLayoutApply}
                    skipCatcherCheck
                    type="PROPERTY_GRID"
                    gridApi={this.api}
                    columnApi={columnApi}
                    layoutId={layoutId}
                    defaultName="Property Layout"
                    savedPropertyGroupId={id}
                    layouts={layouts}
                  />
                </div>
              </div>
              <div className={css.body}>
                <div className="ag-theme-balham" style={{ width: '100%' }}>
                  <AgGridReact {...this.gridProps} />
                </div>
              </div>
            </div>
          </section>
        </div>
        {children}
      </div>
    );
  }
}
/*
                <div className={css.right}>
                  <div className={css.caption}>
                    <div className={css.title}>{name}{` (${size})`}</div>
                  </div>
                </div>
 */

export default withRouter(connect((state) => {
  const context = selectGroupContext(MAIN_CONTEXT)(state);
  const id = context.get('id');
  const info = context.get('info');
  const lmEnabled = selectListManagementEnabled(state);
  const searches = selectSearches(state).toJS();
  const type = info.get('type');

  // Get filters that are either assigned to this group or defaulted for all groups
  const filters = searches.filter(s => s.savedPropertyGroupId === id || s.defaultFilter).reduce((m, s) => ({ ...m, [`${s.filterType}${s.defaultFilter ? 'D' : ''}`]: s }), {});

  // Get cached counts for this group
  const groupCounts = selectGroupCounts(state).get(String(id), Map()).toJS();

  // Init all/add/remove sets and init filter types for each, pulling cached counts if available and setting applicable filter override info
  const groupFilters = ['', '1', '0'].reduce((m, s) => {
    const counts = groupCounts[s] || { dirty: true };

    return ({
      ...m,
      [s]: {
        dirty: counts.dirty,
        filters: GroupListingTypes.map(t => ({
          ...t,
          disabled: !lmEnabled && t.editable,
          customFilter: filters[t.type] || filters[`${t.type}D`] || null,
          quantity: typeof counts[t.type] === 'number' ? counts[t.type] : null,
        })).map(t => ({ ...t, name: (t.customFilter || t.filter).name, description: (t.customFilter || {}).description })),
      },
    });
  }, {});

  return {
    id,
    context,
    groupFilters,
    lmEnabled,
    name: info.get('name'),
    type,
    monitored: type === PropertyGroupTypes.MONITORED,
    smsRegistered: selectSmsEnabled(state) && selectSmsRegistered(state),
    loading: selectLoading(state) || selectCampaignLoading(state),
    groups: selectGroups(state),
    folders: selectFolders(state),
    favorites: selectFavorites(state),
    lists: selectLists(state),
    monitoredLists: selectMonitoredLists(state),
    alerts: selectAlerts(state),
    selection: selectSelection(state),
    layout: selectPropertyGridLayout(state),
    filters: selectFilters(state),
    layouts: selectLayouts(state).filter(l => l.get('type') === 'PROPERTY_GRID').toJS(),
    fullAccess: selectFullAccessPermission(state),
  };
}, {
  setPropertySelection,
  clearPropertySelection,
  loadGroupContext,
  updateGroupContext,
  exportGroupProperties,
  deletePropertyGroup,
  deleteGroupProperties,
  saveGroupProperties,
  searchGroupProperties,
  getSavedPropertyDetail,
  searchPropertyContacts,
  getGroupSearch,
  saveGroupSearch,
  savePropertyGroupFilter,
  deletePropertyGroupFilter,
  getGroupPropertyCounts,
  createSmsCampaign,
  openAppendJobEditor: AppendJobEditor.open,
  openPostcardPreview: PostcardPreviewPopup.open,
  confirm: Confirm.open,
  confirmRemoval: Confirm.confirmRemoval,
  alert: Confirm.alert,
  prompt: Prompt.open,
  openContractManager: ContractManagerPopup.open,
  openAddToGroup: AddToGroup.open,
  openAddToFolder: AddToFolder.open,
  openMap: PropertyGroupMap.open,
  openImport: PropertyImport.open,
  openSearchFilterViewer: SearchFilterViewerPopup.open,
  openExportPropertyConfirmation: ExportPropertyConfirmation.open,
  openLaunchControlConfirmation: LaunchControlConfirmation.open,
  openMailingLabels: MailingLabelsPopup.open,
  savePropertyFolder,
  deletePropertyFolder,
})(deferExecutor(PropertyGroup)));
